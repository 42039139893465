// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-detail-js": () => import("/opt/build/repo/src/templates/product-detail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bedroom-designs-js": () => import("/opt/build/repo/src/pages/bedroom-designs.js" /* webpackChunkName: "component---src-pages-bedroom-designs-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dining-room-designs-js": () => import("/opt/build/repo/src/pages/dining-room-designs.js" /* webpackChunkName: "component---src-pages-dining-room-designs-js" */),
  "component---src-pages-home-interiors-custom-designs-js": () => import("/opt/build/repo/src/pages/home-interiors-custom-designs.js" /* webpackChunkName: "component---src-pages-home-interiors-custom-designs-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-designs-js": () => import("/opt/build/repo/src/pages/kitchen-designs.js" /* webpackChunkName: "component---src-pages-kitchen-designs-js" */),
  "component---src-pages-living-room-designs-js": () => import("/opt/build/repo/src/pages/living-room-designs.js" /* webpackChunkName: "component---src-pages-living-room-designs-js" */),
  "component---src-pages-policies-js": () => import("/opt/build/repo/src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

